import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth.js";
import company from "@/store/modules/company.js";
import financing from "@/store/modules/financing.js";
import profile from "@/store/modules/profile.js";
import common from "@/store/modules/common";
import request from "@/store/modules/request";
import loans from "@/store/modules/loans";
import offerings from "@/store/modules/offerings";
import orders from "@/store/modules/orders";
import notification from "@/store/modules/notification";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    company,
    financing,
    profile,
    common,
    request,
    loans,
    offerings,
    orders,
    notification,
  },
});
