<template>
  <div>
    <v-list class="platform-wrapper mb-12">
      <v-list-item-group
        v-model="model"
        active-class="platform--selected"
        color="purple-1"
        class="d-flex"
      >
        <v-list-item
          class="radius-16"
          v-for="i in 3"
          :key="platforms[i - 1]"
          :class="{ 'me-10': i < 3 }"
        >
          <v-card
            flat
            height="176"
            color="transparent"
            class="d-flex align-center mx-auto"
          >
            <v-img max-width="124" height="124" :src="getPlatformLogo(i)" />
          </v-card>
          <div class="selected-mark">
            <v-img width="30" height="30" :src="selectedIcon" />
          </div>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-btn
      height="52"
      width="320"
      color="purple-2"
      class="white--text mx-auto d-block btn-shadow mb-2 radius-8"
      :disabled="typeof model !== 'number'"
      @click="onSubmit"
    >
      <span class="btn-text font-weight-bold">{{ $t("btn.continue") }}</span>
    </v-btn>
  </div>
</template>
<script>
import selectedIcon from "@/assets/icons/selected.svg";
export default {
  data() {
    return {
      selectedIcon,
      model: null,
      platforms: ["facebook", "google-analytics", "google-ads"],
    };
  },
  methods: {
    getPlatformLogo(index) {
      return require(`@/assets/icons/${this.platforms[index - 1]}.svg`);
    },
    onSubmit() {
      this.$router.push("/sign-up/request");
    },
  },
};
</script>
<style lang="scss"></style>
