<template>
  <div>
    <h2 class="text-center arabic-black f-25 purple-2--text mb-1">
      {{ $t("auth.confirmPhoneNumber") }}
    </h2>
    <p class="gray-1--text text-center mb-6">
      {{ $t("auth.enterOtpDesc") }} {{ phoneNumber }}
    </p>
    <v-card flat max-width="360px" width="100%" class="mx-auto mb-4">
      <v-form @submit.prevent="onConfirm">
        <v-text-field
          color="purple-1"
          height="50"
          v-model="otpValue"
          outlined
          class="centered-input mb-8"
          :error-messages="otpErrors"
        />
        <v-btn
          width="100%"
          height="52"
          color="purple-2"
          class="white--text mx-auto d-block btn-shadow"
          :loading="isSubmitting"
          type="submit"
        >
          <span class="btn-text font-weight-bold">{{ $t("btn.confirm") }}</span>
        </v-btn>
      </v-form>
    </v-card>
    <div class="d-flex align-center justify-center">
      <p class="me-3 gray-3--text">{{ $t("auth.didntReceiveOtp") }}</p>
      <v-btn
        text
        color="purple"
        elevation="0"
        class="align-self-center radius-0 btn-underline--purple"
        @click="onResendOtp"
        :loading="isResending"
      >
        <span class="text-capitalize f-16 font-weight-bold purple-1--text">
          {{ $t("btn.resend") }}
        </span>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      otpValue: "",
    };
  },
  methods: {
    async onConfirm() {
      const res = await this.$store.dispatch("registrationOtp", this.otpValue);
      if (res) {
        this.$router.push("/dashboard");
      }
    },
    onResendOtp() {
      this.$store.dispatch("resendOtp", this.getRequestData());
    },
    getRequestData() {
      return {
        sid: this.sid,
      };
    },
  },

  computed: {
    ...mapState({
      isSubmitting: (state) => state.auth.isSubmitting,
      isResending: (state) => state.auth.isResending,
      sid: (state) => state.auth.sid,
      phoneNumber: (state) => state.auth.phoneNumber,
    }),
    ...mapGetters({
      otpErrors: "otpErrors",
    }),
  },
};
</script>
