import ordersApi from "@/api/orders";

const state = {
  isLoading: false,
  isOrderLoading: false,
  isStatusLoading: false,
  orders: {},
  order: null,
  errors: [],
};

const mutations = {
  resetOfferingOrdersErrors(state) {
    state.errors = [];
  },
  getOfferingOrdersStart(state) {
    state.isLoading = true;
    state.order = null;
    state.errors = [];
  },
  getOfferingOrdersSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.orders = payload;
  },
  getOfferingOrdersFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getOfferingOrderStart(state) {
    state.isOrderLoading = true;
    state.errors = [];
  },
  getOfferingOrderSuccess(state, payload) {
    state.isOrderLoading = false;
    state.errors = [];
    state.order = payload;
  },
  getOfferingOrderFailed(state, payload) {
    state.isOrderLoading = false;
    state.errors = payload;
  },

  actionOfferingOrderStart(state) {
    state.isStatusLoading = true;
    state.errors = [];
  },
  actionOfferingOrderSuccess(state, payload) {
    state.isStatusLoading = false;
    state.order = payload;
  },
  actionOfferingOrderFailed(state, payload) {
    state.isStatusLoading = false;
    state.errors = payload;
  },
};

const actions = {
  async getOfferingOrders(context, payload) {
    try {
      context.commit("getOfferingOrdersStart");

      const res = await ordersApi.getOfferingOrders(payload);
      context.commit("getOfferingOrdersSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingOrdersFailed", e.response.data.error);
      return false;
    }
  },

  async getOfferingOrder(context, payload) {
    try {
      context.commit("getOfferingOrderStart");

      const res = await ordersApi.getOfferingOrder(payload);
      context.commit("getOfferingOrderSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingOrderFailed", e.response.data.error);
      return false;
    }
  },

  async actionOrder(context, payload) {
    try {
      context.commit("actionOfferingOrderStart");

      const res = await ordersApi.orderAction(
        payload.orderId,
        payload.requestBody
      );
      context.commit("actionOfferingOrderSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("actionOfferingOrderFailed", e.response.data.error);
      return false;
    }
  },

  async getOuterInvoice(context, payload) {
    try {
      const res = await ordersApi.outerInvoice(payload);

      const type = res.headers["content-type"].split("/").slice(-1)[0] || "pdf";
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice.${type}`);
      document.body.appendChild(link);
      link.click();

      return true;
    } catch (e) {
      return false;
    }
  },
};

const getters = {
  ordersList(state) {
    return state.orders?.result || [];
  },

  orderDetail(state) {
    return state.order || [];
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
