import requestApi from "@/api/request";
// import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  requests: {},
  errors: [],
  request: null,
};

const mutations = {
  resetRequestsErrors(state) {
    state.errors = [];
  },
  getRequestsStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getRequestsSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.requests = payload;
  },
  getRequestsFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getRequestStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getRequestSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.request = payload;
  },
  getRequestFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  changeStatusStart(state) {
    state.errors = [];
  },
  changeStatusSuccess(state, payload) {
    state.errors = [];
    state.request = payload;
  },
  changeStatusFailed(state, payload) {
    state.errors = payload;
  },
};

const actions = {
  async getRequests(context, payload) {
    try {
      context.commit("getRequestsStart");

      const res = await requestApi.getRequests(payload);
      context.commit("getRequestsSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getRequestsFailed", e.response.data.error);
      return false;
    }
  },

  async getRequest(context, payload) {
    try {
      context.commit("getRequestStart");

      const res = await requestApi.getRequest(payload);
      context.commit("getRequestSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getRequestFailed", e.response.data.error);
      return false;
    }
  },

  async changeRequestStatus(context, payload) {
    try {
      context.commit("changeStatusStart");

      const res = await requestApi.changeStatus(payload);
      context.commit("changeStatusSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("changeStatusFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  requestsList(state) {
    return state.requests?.result || [];
  },

  requestDetail(state) {
    return state.request || [];
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
