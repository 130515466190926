import axios from "@/plugins/axios";

const urlPath = "/customer/api/v1/";

const getConditions = () => {
  return axios.get(`${urlPath}financing-conditions`);
};

const applyFinancing = (data) => {
  return axios.post(`${urlPath}requests/`, data);
};

export default {
  getConditions,
  applyFinancing,
};
