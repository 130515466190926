import axios from "@/plugins/axios";

const urlPath = "/customer/api/v1/profile";

const getProfile = () => {
  return axios.get(urlPath);
};

const changeEmail = (data) => {
  return axios.put(`${urlPath}/email`, data);
};

const resendEmailConfirmation = (data) => {
  return axios.post(`${urlPath}/resend-email-confirmation`, data);
};

const updateProfilePassword = (data) => {
  return axios.post(`${urlPath}/password`, data);
};

export default {
  getProfile,
  changeEmail,
  resendEmailConfirmation,
  updateProfilePassword,
};
