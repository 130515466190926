import Vue from "vue";
import Vuetify from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
import CalendarIcon from "@/assets/icons/customIcons/CalendarIcon.vue";
import SelectorDropdown from "@/assets/icons/customIcons/SelectorDropdown.vue";
import PlusButton from "@/assets/icons/customIcons/PlusButton.vue";
import PartnerIcon from "@/assets/icons/customIcons/PartnerIcon.vue";
import ProviderIcon from "@/assets/icons/customIcons/ProviderIcon.vue";
import FillRequest from "@/assets/icons/customIcons/FillRequest.vue";
import CloseCircle from "@/assets/icons/customIcons/CloseCircle.vue";
import Download from "@/assets/icons/customIcons/Download.vue";

Vue.use(Vuetify);

export const vuetify = new Vuetify({
  rtl: false,
  icons: {
    iconfont: "mdi",
    values: {
      "rapid-calendar": {
        component: CalendarIcon,
      },
      "rapid-selector-dropdown": {
        component: SelectorDropdown,
      },
      "rapid-plus-button": {
        component: PlusButton,
      },
      "rapid-partner-icon": {
        component: PartnerIcon,
      },
      "rapid-provider-icon": {
        component: ProviderIcon,
      },
      "fill-request": {
        component: FillRequest,
      },
      "close-circle": {
        component: CloseCircle,
      },
      "rapid-download": {
        component: Download,
      },
    },
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: {
          base: "#6C3CEA",
          "lighten-1": "#8282c6",
        },
        "light-1": "#FDFDFD",
        blue: "#0992F5",
        "blue-1": "#D8E5EB",
        "gray-1": "#737B8B",
        "gray-2": "#B0C3CC",
        "gray-3": "#909AB0",
        "gray-4": "#7F7F7F",
        "light-bg": "#f5f8fa",
        // "gray-2": "#717171",
        // "gray-3": "#9D9D9D",
        // "gray-4": "#F4F4F4",
        // "gray-5": "#CDCDCD",
        "gray-5": "#E5E5E5",
        // "gray-7": "#D9D9D9",
        // "blue-1": "#3F9ED7",
        yellow: "#F1AC09",
        // red: "#B84545",
        error: "#C90000",
        // "red-2": "#C10000",
        // "red-3": "#F1D4D5",
        "purple-1": "#6C3CEA",
        "purple-2": "#302E75",
        "purple-3": "#707FDD",
        "purple-4": "#8D71FA",
        "purple-light": "#F5F6FF",
        green: "#03A439",
        pink: "#FA71DC",
        success: "#1BAB18",
      },
    },
  },
});
