import axios from "axios";
import { getItem, setItem } from "@/helpers/persistanceStorage";
import { app } from "@/main.js";

const _axios = axios.create({});

_axios.interceptors.request.use((config) => {
  const token = getItem("accessToken");
  const authorizationToken = token ? `Bearer ${token}` : "";

  config.headers.Authorization = authorizationToken;

  return config;
});

_axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        return axios
          .post(
            "/customer/api/v1/refresh",
            {},
            {
              headers: {
                Authorization: `Bearer ${getItem("accessToken")}`,
              },
            }
          )
          .then((res) => {
            setItem("accessToken", res.data.access_token);

            return _axios.request(err.config);
          })
          .catch((e) => {
            if (e.response.status === 401) {
              app.$router.push("/sign-in").catch(() => {});
              sessionStorage.removeItem("accessToken");
            }
            return Promise.reject(e);
          });
      }
    }

    return Promise.reject(err);
  }
);

export default _axios;
