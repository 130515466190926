import axios from "@/plugins/axios";

const url = "/customer/api/v1/offering";

const getOfferingList = (params) => {
  return axios.get(`${url}s/?${params}`);
};

const getOfferingItem = (id) => {
  return axios.get(`${url}/${id}`);
};

const getOfferingPartners = () => {
  return axios.get(`${url}-partners/`);
};

const getOfferingCategories = () => {
  return axios.get(`${url}-categories/`);
};

const getOfferingCard = () => {
  return axios.get(`${url}-card/`);
};

const addOfferingCard = (payload) => {
  return axios.post(`${url}-card/add`, payload);
};

const removeOfferingCard = (payload) => {
  return axios.post(`${url}-card/remove`, payload);
};

const previewOfferingCard = () => {
  return axios.get(`${url}-card/preview`);
};

const previewOfferingOrder = (params) => {
  return axios.get(`${url}-orders/preview?${params}`);
};

const submitOfferingCard = (payload) => {
  return axios.post(`${url}-card/submit`, payload);
};

const submitOfferingOrder = (payload) => {
  return axios.post(`${url}-orders/`, payload);
};

export default {
  getOfferingList,
  getOfferingItem,
  getOfferingPartners,
  getOfferingCategories,
  getOfferingCard,
  addOfferingCard,
  removeOfferingCard,
  previewOfferingCard,
  previewOfferingOrder,
  submitOfferingCard,
  submitOfferingOrder,
};
