<template>
  <div>
    <v-card
      flat
      class="radius-16 gray-border mx-auto py-5"
      max-width="1176"
      width="100%"
    >
      <v-btn
        class="absolute mt-3 ms-8 ps-0 pe-3"
        color="primary"
        text
        @click="$router.go(-1)"
      >
        <v-img
          class="me-2"
          :src="require('@/assets/icons/arrow-back-purple.svg')"
        />
        <span class="btn-text purple-2--text">Back</span>
      </v-btn>
      <v-card flat max-width="500" class="mx-auto">
        <v-card flat width="134" height="80" class="py-5 mx-auto">
          <v-img
            alt=""
            width="114"
            height="40"
            :src="require('@/assets/icons/zid.svg')"
        /></v-card>
        <p class="f-21 purple-2--text arabic-black text-center pb-8">
          {{ $t("company.connectingZidAccount") }}
        </p>
        <div
          v-if="isLoading"
          class="d-flex flex-column align-center mb-12 pt-8"
        >
          <v-progress-circular
            :size="40"
            :width="5"
            indeterminate
            color="purple-1"
            class="mb-9 mx-auto"
          ></v-progress-circular>
          <p class="gray-1--text text-center pb-12">
            {{ $t("auth.pleaseWait") }}
          </p>
        </div>

        <v-card v-if="!isError && !isLoading" width="500" class="mx-auto" flat>
          <div>
            <p class="arabic-bold mb-1">{{ $t("company.result") }}</p>
            <p class="grey--text mb-4">
              {{ $t("company.foundZidStore") }}
            </p>
          </div>
          <div>
            <div class="d-flex store-wrapper d-flex radius-16 hidden mb-8">
              <div class="px-4 py-6 flex-grow-1" style="height: 100px">
                <p class="f-18 arabic-bold">{{ shopName }}</p>
                <p class="gray-1--text f-18">{{ website }}</p>
              </div>
            </div>
          </div>
        </v-card>
        <v-expand-transition>
          <v-card
            v-if="isError"
            color="#FFEEEE"
            min-height="100"
            max-width="100%"
            flat
            class="mb-14 alert-sync py-4 px-4"
          >
            <div class="d-flex">
              <div class="error-description__img me-4 flex-grow-0">
                <img src="@/assets/icons/alert-icon.svg" alt="" />
              </div>
              <div>
                <p class="arabic-bold">
                  {{ $t("errors.shopSyncError") }}
                </p>

                <p v-if="zidSallaStoreErrors.length > 0" class="arabic-default">
                  {{ $t(zidSallaStoreErrors[0]) }}
                </p>

                <p v-else class="arabic-default">
                  {{ $t("errors.canRetry") }}
                </p>
              </div>
            </div>
          </v-card>
        </v-expand-transition>
        <v-btn
          width="224"
          height="52"
          color="purple-2"
          @click="addStore"
          class="white--text mx-auto d-block btn-shadow mb-2 radius-8"
          :disabled="isDisabled"
        >
          <span class="btn-text">{{ $t("btn.continue") }}</span>
        </v-btn>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import selectedIcon from "@/assets/icons/selected.svg";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      model: 0,
      platforms: ["zid", "salla", "shopify"],
      selectedIcon,
      isDisabled: true,
      isLoading: true,
      isError: false,
      website: "",
      shopName: "",
    };
  },

  mounted() {
    this.zidAuth();
  },

  methods: {
    addStore() {
      this.$router.push("/dashboard");
    },
    getPlatformLogo(index) {
      return require(`@/assets/icons/${this.platforms[index - 1]}.svg`);
    },
    async zidAuth() {
      await this.$store.dispatch("getProfile");
      const res = await this.$store.dispatch("zidAuth", {
        id: this.profile?.main_company.cr_number,
        data: this.$route.query,
      });
      this.isLoading = false;
      if (res) {
        this.isDisabled = false;
        this.website = res.data.website;
        this.shopName = res.data.name;
      } else {
        this.isError = true;
        this.model = null;
      }
    },
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile.profile,
    }),
    ...mapGetters({
      zidSallaStoreErrors: "zidSallaStoreErrors",
    }),
    shop() {
      return this.$route.query.shop;
    },
  },
};
</script>
<style lang="scss">
.platform-wrapper .v-list-item--disabled {
  opacity: 0.5;
}

.store-wrapper {
  border: 2px solid #b0c3cc;
}

.alert-sync {
  border-top: 5px solid #c90000 !important;
  border-radius: 4px;
}
</style>
