import authApi from "@/api/auth";
import { i18n } from "@/plugins/i18n";
import { setItem } from "@/helpers/persistanceStorage";
import router from "@/router";

const state = {
  isSubmitting: false,
  isLoggedIn: false,
  sid: null,
  phoneNumber: null,
  errors: [],
  step: 1,
  isResending: false,
};

const mutations = {
  resetErrors(state) {
    state.errors = [];
  },
  setStep(state, payload) {
    state.step = payload;
  },
  authStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  authSuccess(state, payload) {
    state.isSubmitting = false;
    state.errors = [];
    state.sid = payload.sid;
    state.phoneNumber = payload.phone;
  },
  authFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  authOtpStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  authOtpSuccess(state) {
    state.isSubmitting = false;
    state.errors = [];
    state.step = 2;
  },
  authOtpFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },

  resendOtpStart(state) {
    state.isResending = true;
    state.errors = [];
  },
  resendOtpSuccess(state, payload) {
    state.isResending = false;
    state.errors = [];
    state.sid = payload;
  },
  resendOtpFailed(state, payload) {
    state.isResending = false;
    state.errors = payload;
  },

  resetPasswordStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  resetPasswordSuccess(state) {
    state.isSubmitting = false;
    state.errors = [];
  },
  resetPasswordFailed(state, payload) {
    state.isSubmitting = false;
    state.errors = payload;
  },
};

const actions = {
  async registration(context, payload) {
    try {
      context.commit("authStart");

      const response = await authApi.signUp(payload);
      context.commit("authSuccess", response.data);

      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);
      return false;
    }
  },
  async registrationOtp(context, payload) {
    try {
      context.commit("authOtpStart");

      const response = await authApi.signUpOtp({
        sid: context.state.sid,
        otp: payload,
      });
      context.commit("authOtpSuccess");
      setItem("accessToken", response.data.access_token);
      return true;
    } catch (e) {
      context.commit("authOtpFailed", e.response.data.error);
      return false;
    }
  },

  async resendOtp(context, payload) {
    try {
      context.commit("resendOtpStart");

      const res = await authApi.resendOtp(payload);
      context.commit("resendOtpSuccess", res.data.sid);

      return true;
    } catch (e) {
      context.commit("resendOtpFailed", e.response.data.error);
      return false;
    }
  },

  async login(context, payload) {
    try {
      context.commit("authStart");

      const response = await authApi.signIn(payload);
      context.commit("authSuccess", response.data);

      return true;
    } catch (e) {
      context.commit("authFailed", e.response.data.error);
      return false;
    }
  },

  async loginOtp(context, payload) {
    try {
      context.commit("authOtpStart");

      const response = await authApi.signInOtp({
        sid: context.state.sid,
        otp: payload,
      });
      context.commit("authOtpSuccess");
      setItem("accessToken", response.data.access_token);
      return true;
    } catch (e) {
      context.commit("authOtpFailed", e.response.data.error);
      return false;
    }
  },

  async resetPassword(context, payload) {
    try {
      context.commit("resetPasswordStart");

      await authApi.resetPassword(payload);
      context.commit("resetPasswordSuccess");

      return true;
    } catch (e) {
      context.commit("resetPasswordFailed", e.response.data.error);
      return false;
    }
  },

  async updatePassword(context, payload) {
    try {
      context.commit("resetPasswordStart");

      await authApi.updatePassword(payload);
      context.commit("resetPasswordSuccess");

      return true;
    } catch (e) {
      context.commit("resetPasswordFailed", e.response.data.error);

      return false;
    }
  },
  async signOut() {
    try {
      await authApi.signOut();
      sessionStorage.removeItem("accessToken");
      router.push("/sign-in");
      return true;
    } catch (e) {
      return false;
    }
  },
};

const getters = {
  phoneErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "phone_number__required") &&
      result.push(i18n.t("errors.phoneRequired"));
    errors.find((i) => i === "phone_number__invalid") &&
      result.push(i18n.t("errors.phoneInvalid"));
    errors.find((i) => i === "phone_number__exists") &&
      result.push(i18n.t("errors.phoneExists"));
    errors.find((i) => i === "national_id_phone_number__mismatch") &&
      result.push(i18n.t("errors.idPhoneMismatch"));
    return result;
  },
  idNumberErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "id_number__required") &&
      result.push(i18n.t("errors.idNumberRequired"));
    // errors.find((i) => i === "id_number__invalid") &&
    //   result.push(i18n.t("errors.idNumberInvalid"));
    // errors.find((i) => i === "id_number__exists") &&
    //   result.push(i18n.t("errors.idNumberExists"));
    return result;
  },
  emailErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i === "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i === "email__exists") &&
      result.push(i18n.t("errors.emailExists"));
    return result;
  },
  emailForPasswordErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i === "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i === "user__not_found") &&
      result.push(i18n.t("errors.userNotFound"));
    errors.find((i) => i === "action__limit_exceeded") &&
      result.push(i18n.t("errors.resetPasswordTooOften"));
    return result;
  },

  passwordErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "password__required") &&
      result.push(i18n.t("errors.passwordRequired"));
    errors.find((i) => i === "password__invalid") &&
      result.push(i18n.t("errors.passwordInvalid"));
    errors.find((i) => i === "action__limit_exceeded") &&
      result.push(i18n.t("errors.otpLimitExceeded"));
    return result;
  },
  birthdayErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "birthday__required") &&
      result.push(i18n.t("errors.birthdayRequired"));
    errors.find((i) => i === "birthday__invalid") &&
      result.push(i18n.t("errors.birthdayInvalid"));
    return result;
  },
  otpErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "otp__required") &&
      result.push(i18n.t("errors.otpRequired"));
    errors.find((i) => i === "bad_credentials") &&
      result.push(i18n.t("errors.otpInvalid"));
    errors.find((i) => i === "action__limit_exceeded") &&
      result.push(i18n.t("errors.otpLimitExceeded"));
    errors.find((i) => i === "otp__expired") &&
      result.push(i18n.t("errors.otpExpired"));
    return result;
  },
  newPasswordErrors({ errors }) {
    const result = [];
    errors.find((item) => item === "password__required") &&
      result.push(i18n.t("errors.passwordRequired"));
    errors.find((item) => item === "password__invalid") &&
      result.push(i18n.t("errors.passwordInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
