<template>
  <div class="header" v-if="data">
    <div
      class="d-flex flex-wrap align-center height100 width100 px-md-6 justify-space-between header"
    >
      <company-selector v-if="data.main_company" />
      <v-spacer></v-spacer>
      <v-btn
        icon
        elevation="0"
        color="gray"
        class="me-5"
        @click="changeLanguage"
      >
        <v-img
          height="24"
          max-width="24"
          :src="require('@/assets/icons/language.svg')"
        ></v-img>
      </v-btn>

      <v-expand-transition>
        <div class="d-flex align-center me-5">
          <messages-view />
        </div>
      </v-expand-transition>

      <v-menu offset-y nudge-bottom="10">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="header__menu">
            <v-skeleton-loader
              v-if="isLoading"
              type="avatar, sentences"
              class="header__skeleton d-flex align-center"
            ></v-skeleton-loader>
            <div v-else-if="data" class="d-flex align-center">
              <v-avatar size="40" color="gray lighten-2">
                <v-img
                  v-if="data.picture"
                  cover
                  :src="data.picture"
                  alt="avatar"
                ></v-img>
                <v-img
                  v-else
                  cover
                  :src="emptyUser"
                  max-width="40"
                  height="40"
                  alt="avatar"
                ></v-img>
              </v-avatar>
              <div class="ms-4">
                <div class="font-weight-medium">
                  {{ data.first_name_en }} {{ data.family_name_en }}
                </div>
                <div
                  class="text-body-2 gray-2--text text-capitalize"
                  v-if="data.role"
                >
                  {{ $t(`role.${data.role}`) }}
                </div>
              </div>
              <v-icon color="gray-2" class="ms-4">mdi-chevron-down</v-icon>
            </div>
          </div>
        </template>
        <v-list class="list-wrapper" elevation="0">
          <v-list-item link to="/profile?tap=account">
            <v-list-item-icon>
              <v-img :src="userIcon" :max-width="24" height="24" />
            </v-list-item-icon>
            <v-list-item-title>{{ $t("label.profile") }}</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="onLogout">
            <v-list-item-icon>
              <v-img :src="logout" :max-width="24" height="24" />
            </v-list-item-icon>
            <v-list-item-title>{{ $t("auth.logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import emptyUser from "@/assets/icons/empty-user.svg";
import dropdown from "@/assets/icons/dropdown.svg";
import userIcon from "@/assets/icons/user-filled.svg";
import logout from "@/assets/icons/logout.svg";

import { mapState } from "vuex";
import CompanySelector from "@/components/header/CompanySelector.vue";
import axios from "@/plugins/axios";
import MessagesView from "@/components/shared/MessagesView.vue";
export default {
  components: { MessagesView, CompanySelector },
  data() {
    return {
      emptyUser,
      dropdown,
      userIcon,
      logout,
      drawer: false,
      isTransparent: false,
    };
  },
  mounted() {
    this.checkLocalization();
    this.$store.dispatch("getProfile");
  },
  methods: {
    onLogout() {
      this.$store.dispatch("signOut");
    },
    checkLocalization() {
      axios.defaults.headers.common["Accept-Language"] =
        localStorage.getItem("lang") || "en";
      const locale = localStorage.getItem("lang");
      this.$i18n.locale = locale || "en";
      this.$vuetify.rtl = locale === "ar";
    },
    changeLanguage() {
      const newLang = this.$i18n.locale === "ar" ? "en" : "ar";
      this.$i18n.locale = newLang;
      this.$vuetify.rtl = newLang === "ar";
      axios.defaults.headers.common["Accept-Language"] = newLang;
      localStorage.setItem("lang", newLang);
      this.$store.commit("setLang", newLang);
    },
  },
  computed: {
    ...mapState({
      data: (state) => state.profile.profile,
      isLoading: (state) => state.profile.isLoading,
      isLoggedIn: (state) => state.auth.isLoggedIn,
    }),
  },
};
</script>

<style lang="scss" scoped>
.arrow-image {
  transition: transform 0.3s;
}
.header {
  height: 64px;
  border-bottom: 1px solid #d8e5eb !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.04);
  background: white;
  position: sticky;
  z-index: 20;
  top: 0;

  &__menu[aria-expanded="true"] .arrow-image {
    transform: rotate(180deg);
  }

  &__skeleton {
    width: 200px;
  }
}
</style>
