export const roundedToThousands = (value = 0) => {
  return value / 100000;
};

export const roundedToUnits = (value = 0) => {
  return Math.round(value * 1000);
};

export const toCents = (value) => {
  return Math.trunc(value * 100);
};

export const roundWithoutCents = (value = 0) => {
  return Math.round(value / 100);
};

export const toFormattedAmountWithCommaAndPoint = (value) => {
  if (typeof value !== "number") {
    return value;
  }

  return (value / 100).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
