<template>
  <v-card flat max-width="620" class="mx-auto">
    <h1 class="text-center arabic-black f-24 purple-2--text mb-2">
      {{
        step === 1
          ? $t("headers.choosePlatform")
          : $t("headers.marketingPlatform")
      }}
    </h1>
    <p class="gray-1--text text-center mb-10">
      {{ $t("financing.linkStoreDesc") }}
    </p>

    <router-view />

    <MarketingPlatform v-if="type === 'analyzing'" />
  </v-card>
</template>
<script>
import MarketingPlatform from "@/components/registration/thirdStep/MarketingPlatform.vue";

export default {
  components: { MarketingPlatform },
  data() {
    return {
      step: 1,
    };
  },
  mounted() {
    this.$store.commit("setStep", 3);
  },

  computed: {
    type() {
      return this.$route.query.type;
    },
  },
};
</script>
