import axios from "@/plugins/axios";

const urlPath = "/customer/api/v1/loans/";

const getLoans = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getLoan = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const getLoanTransactions = (id) => {
  return axios.get(`${urlPath}${id}/transactions/`);
};

export default {
  getLoans,
  getLoan,
  getLoanTransactions,
};
