<template>
  <div class="purple-2 menu pt-4" :style="sidebarPosition">
    <div class="d-flex justify-center mb-12">
      <v-img
        max-width="174"
        height="40"
        :src="require('@/assets/icons/logo-white.svg')"
      />
    </div>
    <v-list flat color="transparent" class="pt-4">
      <v-list-item-group>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="`/${item.link}`"
          class="font-weight-medium ms-4 me-6 my-1"
          active-class="menu-item--active"
          :class="item.enabled ? '' : 'd-none'"
        >
          <v-list-item-icon class="my-4 me-3">
            <v-img
              height="20"
              max-width="20"
              :src="getImage(item.icon)"
              class="menu-icon"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content class="py-2">
            <v-list-item-title class="menu__item-title">{{
              $t(`menu.${item.title}`)
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      items: [
        {
          title: "dashboard",
          link: "dashboard",
          icon: "dashboard",
          enabled: true,
        },
        {
          title: "company",
          link: "company",
          icon: "customers",
          enabled: true,
        },
        {
          title: "financing",
          link: "financing",
          icon: "loans",
          enabled: false,
        },
        {
          title: "requests",
          link: "requests",
          icon: "requests",
          enabled: true,
        },
        {
          title: "BNPLPartners",
          link: "offerings",
          icon: "offerings",
          enabled: false,
        },
        {
          title: "orders",
          link: "orders",
          icon: "orders",
          enabled: true,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("getConfig");
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icons/menu/${icon}.svg`);
    },
  },
  watch: {
    config() {
      this.items[2].enabled = this.loansEnabled;
      this.items[4].enabled = this.offeringEnabled;
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.common.config,
    }),
    ...mapGetters({
      loansEnabled: "loansEnabled",
      offeringEnabled: "offeringEnabled",
    }),
    sidebarPosition() {
      return this.$vuetify.rtl ? { right: "0" } : { left: "0" };
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  top: 0;
  min-height: 100vh;
  width: 240px;

  &:dir(rtl) {
    left: unset;
    right: 0;
  }

  & .v-list {
    padding: 0 !important;
  }
  & .v-list-item {
    padding-left: 24px !important;
    border-radius: 5px;
    transition: 0.2s background;
  }

  &__item-title {
    color: #8282c6;
    z-index: 1;
  }
}

.menu-item--active {
  background: rgba(185, 194, 255, 0.15);

  & .menu__item-title {
    color: white;
  }
  & .menu-icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
      hue-rotate(299deg) brightness(102%) contrast(102%);
  }
}

.items-group {
  &__title {
    color: rgba(255, 255, 255, 0.8);
  }

  &__line {
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
  }
}
</style>
