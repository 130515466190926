<template>
  <div>
    <Loader v-if="isLoading" />

    <v-card v-else-if="conditions" flat max-width="640" class="mx-auto">
      <h1 class="text-center arabic-black f-24 purple-2--text mb-10">
        {{ $t("headers.selectAmount") }}
      </h1>
      <v-form class="d-flex flex-column">
        <p class="f-18 arabic-bold mb-4">
          {{ $t("financing.willBeDetermined") }}
        </p>
        <v-range-slider
          v-model="amountRange"
          :max="maxLoan"
          :min="minLoan"
          hide-details
          class="money-track"
          color="purple-1"
          track-color="gray-5"
          thumb-label="always"
          height="100"
        >
          <template v-slot:thumb-label="props">
            <div class="thumb-container">{{ props.value }}K</div>
          </template>
          <template v-slot:append>
            <span>*{{ $t("currency.SAR") }}</span>
          </template>
        </v-range-slider>
        <div class="d-flex align-center mb-12">
          <fieldset class="me-10 flex-grow-1">
            <label class="mb-2px">
              {{ `${$t("label.from")}, ${$t("currency.SAR")}` }}
            </label>
            <v-text-field
              color="purple-1"
              outlined
              height="50"
              v-model="financing.minLoanValue"
              :placeholder="`${$t('label.amount')}`"
              readonly
              hide-details
            ></v-text-field>
          </fieldset>

          <fieldset class="flex-grow-1" style="max-width: 360px">
            <label class="mb-2px">
              {{ `${$t("label.to")}, ${$t("currency.SAR")}` }}
            </label>
            <v-text-field
              color="purple-1"
              outlined
              height="50"
              v-model="financing.maxLoanValue"
              :placeholder="`${$t('label.amount')}`"
              readonly
              hide-details
            ></v-text-field>
          </fieldset>
        </div>
        <p class="f-18 arabic-bold mb-4">
          {{ $t("financing.specifyPeriod") }}
        </p>
        <v-slider
          v-model="financing.periodValue"
          :max="maxPeriod"
          :min="minPeriod"
          hide-details
          class="period-track"
          color="purple-1"
          track-color="gray-5"
          thumb-label="always"
          height="100"
        >
          <template v-slot:thumb-label="props">
            <div class="thumb-container">{{ props.value }}</div>
          </template>
          <template v-slot:append>
            <span>*{{ $tc("common.month", 2) }}</span>
          </template>
        </v-slider>
        <label class="mb-2px">
          {{ `${$t("label.paymentPeriod")}, ${$tc("common.month", 2)}` }}
        </label>
        <fieldset style="max-width: 200px" class="mb-12">
          <v-text-field
            outlined
            height="50"
            v-model="financing.periodValue"
            :placeholder="`${$t('label.amount')}`"
            readonly
            hide-details
            color="purple-1"
          ></v-text-field>
        </fieldset>
        <div class="d-flex align-center justify-space-between mb-12">
          <v-card flat max-width="460" class="me-4 d-flex flex-column">
            <h4 class="f-18 arabic-bold">
              {{ $t("headers.prescribedPercentage") }}
            </h4>
            <p class="gray-1--text">{{ $t("financing.imposedPercentage") }}</p>
          </v-card>
          <v-card flat max-width="100">
            <v-text-field
              outlined
              height="64"
              :value="prescribedPercentage"
              readonly
              hide-details
              color="purple-1"
              class="centered-input percentage-field"
            ></v-text-field>
          </v-card>
        </div>

        <v-btn
          color="purple-2"
          height="52"
          width="360"
          class="btn-shadow radius-8 white--text mx-auto"
          :loading="isSubmitting"
          @click="onApplyFinancing"
        >
          <span class="btn-text font-weight-bold">{{
            $t("btn.continue")
          }}</span>
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import Loader from "@/components/shared/Loader.vue";
import { mapState } from "vuex";
import { roundedToThousands, roundedToUnits, toCents } from "@/helpers/numeric";

export default {
  components: { Loader },
  data() {
    return {
      amountRange: [1, 100],
      financing: {
        minLoanValue: "",
        maxLoanValue: "",
        periodValue: "",
      },
      prescribedPercentage: "2%",
    };
  },

  mounted() {
    this.$store.commit("setStep", 4);
    this.$store.dispatch("getFinancingConditions");
  },

  methods: {
    getRequestData() {
      const { minLoanValue, maxLoanValue, periodValue } = this.financing;
      return {
        loan_amount_from: toCents(minLoanValue),
        loan_amount_to: toCents(maxLoanValue),
        loan_period: periodValue,
      };
    },
    async onApplyFinancing() {
      const res = await this.$store.dispatch(
        "applyFinancing",
        this.getRequestData()
      );

      if (res) {
        this.$router.push("/dashboard");
      }
    },
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.financing.isLoading,
      conditions: (state) => state.financing.conditions,
      isSubmitting: (state) => state.financing.isSubmitting,
    }),
    minLoan() {
      return roundedToThousands(this.conditions?.loan_amount_min || 0);
    },
    maxLoan() {
      return roundedToThousands(this.conditions?.loan_amount_max || 1000);
    },
    minPeriod() {
      return this.conditions?.loan_period_min || 0;
    },
    maxPeriod() {
      return this.conditions?.loan_period_max || 0;
    },
  },
  watch: {
    conditions(value) {
      this.amountRange = [
        roundedToThousands(value?.loan_amount_min),
        roundedToThousands(value?.loan_amount_max),
      ];
    },
    amountRange(value) {
      this.financing.minLoanValue = roundedToUnits(value[0]);
      this.financing.maxLoanValue = roundedToUnits(value[1]);
    },
  },
};
</script>

<style lang="scss">
.v-slider__track-container {
  height: 10px !important;
}
.v-slider__track-background {
  border-radius: 20px !important;
}

.money-track {
  position: relative;
}

.period-track {
  position: relative;

  & .v-slider__track-fill {
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
  }
}

.money-track,
.period-track {
  & .v-input__append-outer {
    position: absolute;
    right: 10px;
    bottom: 15px;
  }
}

.percentage-field input {
  font-size: 22px;
  font-weight: bold;
}
</style>
