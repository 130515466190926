import axios from "@/plugins/axios";

const urlPath = "/customer/api/v1/offering-orders/";

const getOfferingOrders = (params) => {
  return axios.get(`${urlPath}?${params}`);
};

const getOfferingOrder = (id) => {
  return axios.get(`${urlPath}${id}`);
};

const orderAction = (orderId, data) => {
  return axios.post(`${urlPath}${orderId}/action`, data);
};

const outerInvoice = (orderId) => {
  return axios.get(`${urlPath}${orderId}/invoice-download`, {
    responseType: "blob",
  });
};

export default {
  getOfferingOrders,
  getOfferingOrder,
  orderAction,
  outerInvoice,
};
