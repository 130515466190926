import axios from "@/plugins/axios";

const getAllowedCountries = () => {
  return axios.get("/api/v1/allowed-country-codes/");
};

const getConfig = () => {
  return axios.get("/customer/api/v1/config");
};

const downloadFile = (url) => {
  return axios.get(url, { responseType: "blob" }).then((response) => {
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.pdf"); // or any other extension
    document.body.appendChild(link);
    link.click();
  });
};

export default {
  getAllowedCountries,
  getConfig,
  downloadFile,
};
