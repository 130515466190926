<template>
  <div class="d-flex flex-column">
    <h1 class="text-center arabic-black f-30 purple-2--text mb-8">
      {{ $t("auth.newRegistration") }}
    </h1>
    <div class="type-toggle d-inline-block align-self-center mb-8">
      <v-btn
        width="132"
        height="44"
        color="purple-3"
        elevation="0"
        :text="isIqama"
        @click="isIqama = false"
        class="radius-8"
      >
        <span :class="!isIqama ? 'white--text' : 'gray-2--text'">
          <span class="btn-text me-1 text-capitalize">{{
            $t("btn.national")
          }}</span>
          <span>{{ $t("btn.id") }}</span>
        </span>
      </v-btn>
      <v-btn
        width="132"
        height="44"
        color="purple-3"
        class="btn-shadow radius-8"
        elevation="0"
        :text="!isIqama"
        @click="isIqama = true"
      >
        <span
          class="btn-text me-1"
          :class="isIqama ? 'white--text' : 'gray-2--text'"
          >{{ $t("btn.iqama") }}</span
        >
      </v-btn>
    </div>
    <v-form class="black--text" ref="form" @submit.prevent="onSubmit">
      <div class="d-flex align-center">
        <fieldset class="me-10 flex-grow-1">
          <label class="mb-2px">{{
            this.isIqama ? $t("btn.iqama") : $t("label.idNumber")
          }}</label>
          <v-text-field
            color="purple-1"
            outlined
            height="50"
            v-model="user.id"
            :placeholder="`${$t('label.idNumber')}`"
            :error-messages="idNumberErrors"
          ></v-text-field>
        </fieldset>
        <fieldset class="flex-grow-1">
          <label class="mb-2px">{{ $t("label.birth") }}</label>
          <v-menu
            ref="birthMenu"
            v-model="isBirthPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                color="purple-1"
                outlined
                height="50"
                :value="user.birth"
                :placeholder="`${$t('label.birth')}`"
                readonly
                v-bind="attrs"
                v-on="on"
                :error-messages="birthdayErrors"
              >
                <template #append
                  ><v-icon v-on="on" v-bind="attrs" class="me-1"
                    >$vuetify.icons.rapid-calendar</v-icon
                  ></template
                >
              </v-text-field>
            </template>
            <v-hijri-date-picker
              class="birthday-menu"
              v-model="user.birth"
              no-title
              :locale="$root.$i18n.locale === 'en' ? 'en' : 'ar'"
              :show-current="false"
              @input="isBirthPicker = false"
              :max="minBirth"
            >
            </v-hijri-date-picker>
          </v-menu>
        </fieldset>
      </div>

      <div class="d-flex align-center">
        <fieldset class="me-10 flex-grow-1">
          <label class="mb-2px">{{ $t("label.email") }}</label>
          <v-text-field
            color="purple-1"
            outlined
            height="50"
            v-model="user.email"
            :placeholder="`${$t('label.email')}`"
            :error-messages="emailErrors"
          ></v-text-field>
        </fieldset>

        <fieldset class="flex-grow-1" style="max-width: 360px">
          <label class="mb-2px">{{ $t("label.mobileNumber") }}</label>

          <phone-field v-model="user.phone" :code.sync="user.countryCode" />
        </fieldset>
      </div>

      <div class="d-flex align-center mb-9">
        <fieldset class="flex-grow-1 me-10">
          <label class="mb-2px">{{ $t("label.password") }}</label>
          <v-text-field
            color="purple-1"
            outlined
            height="50"
            v-model="user.password"
            :placeholder="`${$t('label.password')}`"
            :error-messages="passwordErrors"
            :type="show1 ? 'text' : 'password'"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show1 = !show1"
          ></v-text-field>
        </fieldset>
        <fieldset class="flex-grow-1">
          <label class="mb-2px">{{ $t("label.confirmPassword") }}</label>
          <v-text-field
            color="purple-1"
            outlined
            height="50"
            v-model="user.confirmPassword"
            :placeholder="`${$t('label.confirmPassword')}`"
            :rules="confirmPasswordErrors"
            :type="show2 ? 'text' : 'password'"
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show2 = !show2"
          ></v-text-field>
        </fieldset>
      </div>

      <v-btn
        max-width="320"
        width="100%"
        height="52"
        color="purple-2"
        class="white--text mx-auto d-block btn-shadow radius-8 mb-4"
        type="submit"
        :loading="isSubmitting"
      >
        <span class="btn-text font-weight-bold">{{ $t("btn.continue") }}</span>
      </v-btn>
      <v-card
        flat
        max-width="402"
        class="mx-auto text-center gray-1--text f-13"
      >
        {{ $t("auth.byRegistering") }}
        <router-link
          to=""
          class="purple-1--text text-decoration-underline mx-1"
        >
          {{ $t("label.termAndCond") }}
        </router-link>
        {{ $t("auth.ofPlatform") }}
      </v-card>
    </v-form>
  </div>
</template>
<script>
import VHijriDatePicker from "vuetify-umalqura";
import { getGregorianDate, subtractYears } from "@/helpers/date";
import PhoneField from "@/components/shared/PhoneField";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    PhoneField,
    VHijriDatePicker,
  },
  data() {
    return {
      show2: false,
      show1: false,
      isIqama: false,
      isBirthPicker: false,
      minBirth: subtractYears(),
      user: {
        id: "",
        birth: subtractYears(),
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        countryCode: "ua",
      },
      confirmPasswordErrors: [],
    };
  },
  emits: ["otp-view"],

  methods: {
    async registerUser() {
      const res = await this.$store.dispatch(
        "registration",
        this.getQueryData()
      );

      if (res) {
        this.$emit("otp-view");
      }
    },
    addConfirmPasswordValidation() {
      this.confirmPasswordErrors = [
        (v) => {
          if (!v && !!this.user.password) {
            return `${this.$t("errors.confirmPasswordRequired")}`;
          }
          return true;
        },
        (v) => {
          const password = this.user.password;
          if (!!v && !!password && v !== password) {
            return this.$t("errors.confirmPasswordInvalid");
          }
          return true;
        },
      ];
    },
    async onSubmit() {
      this.addConfirmPasswordValidation();

      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.registerUser();
        }
      });
    },
    getQueryData() {
      const newUser = {
        id_type: this.isIqama ? "iqama" : "national_id",
        id_number: this.user.id,
        phone_code: this.user.countryCode.toUpperCase(),
        phone_number: this.user.phone,
        email: this.user.email,
        birthday: getGregorianDate(this.user.birth),
        password: this.user.password,
      };

      return newUser;
    },
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.auth.isSubmitting,
    }),
    ...mapGetters({
      idNumberErrors: "idNumberErrors",
      emailErrors: "emailErrors",
      passwordErrors: "passwordErrors",
      birthdayErrors: "birthdayErrors",
    }),
  },
};
</script>
<style lang="scss" scoped>
.type-toggle {
  border: 1px solid #b0c3cc;
  border-radius: 8px;
  margin: 0 auto;
}

fieldset {
  max-width: 360px;
}
</style>
