<template>
  <div class="phone-container">
    <v-text-field
      color="purple-1"
      placeholder="Number"
      outlined
      :value="temp"
      @input="changePhone"
      maxlength="15"
      height="50"
      :error-messages="phoneErrors.length ? phoneErrors : phoneInputErrors"
    >
      <template v-slot:prepend-inner>
        <v-menu bottom transition="scale-transition" attach=".phone-container">
          <template v-slot:activator="{ on, attrs }">
            <div
              class="d-flex align-center link pointer me-2"
              v-bind="attrs"
              v-on="on"
            >
              <img
                class="rounded-circle me-2"
                width="28"
                height="28"
                :src="flagImg(code)"
              />
              <span class="black--text">
                {{ phoneCode }}
              </span>
            </div>
          </template>
          <v-list class="px-2 list-wrapper">
            <v-text-field
              color="purple-1"
              class="mb-2 search-input"
              placeholder="Country"
              outlined
              hide-details="true"
              v-model="searchedCountry"
              height="48"
              @click.stop
              bg-color="white"
            >
              <template v-slot:prepend-inner>
                <v-img width="24" :src="searchIcon" class="me-2" />
              </template>
            </v-text-field>

            <v-list class="view-without-scroll px-2 countries-list">
              <v-list-item
                v-for="item in requestsFlag"
                :key="item.ct"
                class="country-item d-flex align-center py-0 px-3"
                @click="() => changeCountryCode(item.ct)"
                link
              >
                <div class="d-flex align-center">
                  <img max-width="28" :src="flagImg(item.ct)" class="me-2" />
                  <span class="me-1">{{ item.en }}</span>
                  <span>(+{{ item.cl }})</span>
                </div>
              </v-list-item>
            </v-list>
          </v-list>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import json from "@/codes.json";
import countryApi from "@/api/common";
import { mapGetters } from "vuex";
import searchIcon from "@/assets/icons/search.svg";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    phoneInputErrors: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  emits: ["input", "update:code"],
  data() {
    return {
      allowedCountries: [],
      modal: false,
      flagList: json,
      searchedCountry: "",
      temp: "",
      searchIcon,
    };
  },

  mounted() {
    this.loadCountries();
  },
  computed: {
    ...mapGetters({
      phoneErrors: "phoneErrors",
    }),
    countriesList() {
      const allowedCodes = this.allowedCountries.map((country) => country.code);
      return json.filter((country) => {
        return allowedCodes.includes(country.ct);
      });
    },
    requestsFlag() {
      return this.countriesList.filter((country) => {
        return Object.values(country).some((key) => {
          return key
            .toString()
            .toLowerCase()
            .includes(this.searchedCountry.toLowerCase());
        });
      });
    },
    phoneCode() {
      return `+${this.flagList.find((item) => item.ct == this.code).cl}`;
    },
  },

  methods: {
    flagImg(code = this.code) {
      return require(`@/assets/icons/flags/img_flag_${code}.png`);
    },
    async loadCountries() {
      try {
        const res = await countryApi.getAllowedCountries();

        this.allowedCountries = res.data;
      } catch {
        console.log("Something went wrong");
      }
    },
    changePhone(newValue) {
      this.$emit("input", newValue);
    },
    changeCountryCode(code) {
      this.$emit("update:code", code);
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-container {
  position: relative;

  & .v-menu__content {
    max-width: 100% !important;
  }
}

.modal {
  position: absolute;
  width: 100%;
  height: 310px;
  left: 0;
  bottom: -320px;
  z-index: 100;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  padding: 8px;
  background: white;
}

.country-item {
  padding: 14px 0;
  border-bottom: 1px solid #f4f4f4;
  cursor: pointer;
}

.list-wrapper.v-list {
  margin-top: 0px !important;
  width: 360px !important;
  left: -12px !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 16px !important;
  filter: drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.06)) !important;
  box-shadow: none !important;
  overflow-y: auto;
  height: 310px !important;
}

.countries-list.v-list {
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-list-item__content {
  display: flex !important;
  align-items: center !important;
}

.v-menu__content {
  margin-top: 50px !important;
  margin-left: -12px !important;
  border-radius: 16px !important;
  filter: drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.06)) !important;
  box-shadow: none !important;
}
</style>
