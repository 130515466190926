import "hijri-date";
import HijriDate from "hijri-date/lib/safe";

export const subtractYears = (numOfYears = 18, date = new Date()) => {
  date.setFullYear(date.getFullYear() - numOfYears);
  const maxDate = date.toHijri().subtractDay();

  return maxDate.format("yyyy-mm-dd");
};

export const getGregorianDate = (hijriDate) => {
  if (!hijriDate) {
    return "";
  }
  const [year, month, day] = hijriDate.split("-");

  let date = new HijriDate(+year, +month, +day + 1).toGregorian();

  const res =
    date.getFullYear() + "-" + (+date.getMonth() + 1) + "-" + date.getDate();
  return res;
};

export const formattedDateTime = (value) => {
  if (!value) {
    return "";
  }
  const date = new Date(value);
  return date.toLocaleDateString("en-US", {
    hour12: false,
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};
