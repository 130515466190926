<template>
  <div>
    <Form v-if="!isOtpView" @otp-view="isOtpView = true" />
    <OtpConfirmation v-else />
  </div>
</template>
<script>
import Form from "@/components/registration/Form";
import OtpConfirmation from "@/components/registration/OtpConfirmation";
export default {
  components: { OtpConfirmation, Form },

  mounted() {
    this.$store.commit("setStep", 1);
  },

  data() {
    return {
      isOtpView: false,
    };
  },
};
</script>
