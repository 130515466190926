import axios from "@/plugins/axios";

const urlPath = "/customer/api/v1/";

const findCompany = (params) => {
  return axios.get(`${urlPath}find-company?${params}`);
};

const attachCompany = (data) => {
  return axios.post(`${urlPath}companies/`, data);
};

const connectStore = ({ id, data }) => {
  return axios.post(`${urlPath}companies/${id}/shopify-connect`, data);
};

const connectSallaStore = ({ id, data }) => {
  return axios.post(`${urlPath}companies/${id}/salla-connect`, data);
};

const connectZidStore = ({ id, data }) => {
  return axios.post(`${urlPath}companies/${id}/zid-connect`, data);
};

const shopifyAuth = ({ id, data }) => {
  return axios.post(`${urlPath}companies/${id}/shopify-authorize`, data);
};

const sallaAuth = ({ id, data }) => {
  return axios.post(`${urlPath}companies/${id}/salla-authorize`, data);
};

const zidAuth = ({ id, data }) => {
  return axios.post(`${urlPath}companies/${id}/zid-authorize`, data);
};

const getCompanies = (params = "") => {
  return axios.get(`${urlPath}companies/?${params}`);
};

const changeCurrentCompany = (id) => {
  return axios.post(`${urlPath}companies/${id}/set-current`);
};

const getCompany = () => {
  return axios.get(`${urlPath}company`);
};

const getCounters = () => {
  return axios.get(`${urlPath}company/statistic/counters`);
};

const getCompanyPerformance = () => {
  return axios.get(`${urlPath}company/statistic/performance-chart`);
};

export default {
  findCompany,
  attachCompany,
  connectStore,
  shopifyAuth,
  sallaAuth,
  zidAuth,
  getCompanies,
  changeCurrentCompany,
  getCompany,
  getCounters,
  getCompanyPerformance,
  connectSallaStore,
  connectZidStore,
};
