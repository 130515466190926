<template>
  <div v-if="companies">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="blue-1" outlined dark v-bind="attrs" v-on="on">
          <span class="btn-text purple-2--text font-weight-bold">{{
            companyName
          }}</span>
          <v-icon color="purple-3">mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item-group
          mandatory
          v-model="selectedCompany"
          @change="handleCompanyChange"
          color="purple-1"
        >
          <v-list-item
            class="radius-16"
            v-for="item in companies"
            :key="item.cr_number"
            :value="item.cr_number"
          >
            {{ item.cr_name }}
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      selectedCompany: null,
    };
  },
  mounted() {
    this.loadCompanies();
  },
  methods: {
    loadCompanies() {
      this.$store.dispatch("getCompanies");
    },
    handleCompanyChange(id) {
      this.$store.dispatch("changeCurrentCompany", id);
    },
  },
  computed: {
    ...mapState({
      companies: (state) => state.company.companies,
      isCookieCompany: (state) => state.company.isCookieCompany,
    }),
    companyName() {
      return this.companies.find(
        (item) => item.cr_number === this.selectedCompany
      )?.cr_name;
    },
  },
  watch: {
    companies() {
      const crNumber = this.companies.find(
        (company) => !!company.current
      )?.cr_number;
      this.selectedCompany = crNumber;
      // if (!this.isCookieCompany) {
      //   this.handleCompanyChange(crNumber);
      // }
    },
  },
};
</script>
<style lang="scss"></style>
