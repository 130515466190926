import profileApi from "@/api/profile";
import authApi from "@/api/auth";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  resendLoading: false,
  profile: null,
  errors: [],
};

const mutations = {
  getProfileStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getProfileSuccess(state, payload) {
    state.isLoading = false;
    state.profile = payload;
    state.errors = [];
  },
  getProfileFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
  changeEmailStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  changeEmailSuccess(state, payload) {
    state.isLoading = false;
    state.profile = payload;
    state.errors = [];
  },
  changeEmailFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
  confirmEmailStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  confirmEmailSuccess(state, payload) {
    state.isLoading = false;
    state.profile = payload;
    state.errors = [];
  },
  confirmEmailFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
  resendEmailStart(state) {
    state.resendLoading = true;
    state.errors = [];
  },
  resendEmailSuccess(state, payload) {
    state.resendLoading = false;
    state.profile = payload;
    state.errors = [];
  },
  resendEmailFailed(state, payload) {
    state.resendLoading = false;
    state.errors = payload;
  },
  updatePasswordStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  updatePasswordSuccess(state, payload) {
    state.isLoading = false;
    state.profile = payload;
    state.errors = [];
  },
  updatePasswordFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};
const actions = {
  async getProfile(context) {
    try {
      context.commit("getProfileStart");

      const response = await profileApi.getProfile();
      // if (!response.data.main_company) {
      //   throw {
      //     response: {
      //       data: {
      //         error: ["company__required"],
      //       },
      //     },
      //   };
      // }

      context.commit("getProfileSuccess", response.data);
      return true;
    } catch (e) {
      context.commit("getProfileFailed", e.response.data.error);
      return false;
    }
  },
  async changeProfileEmail(context, payload) {
    try {
      context.commit("changeEmailStart");

      const response = await profileApi.changeEmail(payload);
      context.commit("changeEmailSuccess", response.data);

      return true;
    } catch (e) {
      context.commit("changeEmailFailed", e.response.data.error);
      return false;
    }
  },
  async confirmEmail(context, payload) {
    try {
      context.commit("confirmEmailStart");

      const response = await authApi.confirmEmail(payload);
      context.commit("confirmEmailSuccess", response.data);

      return true;
    } catch (e) {
      context.commit("confirmEmailFailed", e.response.data.error);
      return false;
    }
  },
  async resendEmailConfirmation(context, payload) {
    try {
      context.commit("resendEmailStart");

      const response = await profileApi.resendEmailConfirmation(payload);
      context.commit("resendEmailSuccess", response.data);

      return true;
    } catch (e) {
      context.commit("resendEmailFailed", e.response.data.error);
      return false;
    }
  },
  async updateProfilePassword(context, payload) {
    try {
      context.commit("updatePasswordStart");

      const response = await profileApi.updateProfilePassword(payload);
      context.commit("updatePasswordSuccess", response.data);

      return true;
    } catch (e) {
      context.commit("updatePasswordFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  isCompanyAdded(state) {
    return state.profile ? state.profile.is_company_added : true;
  },
  isRequestCreated(state) {
    return state.profile ? state.profile.is_request_created : true;
  },
  isLoading(state) {
    return state.isLoading;
  },
  changeEmailErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "email__invalid") &&
      result.push(i18n.t("errors.emailInvalid"));
    errors.find((i) => i === "email__required") &&
      result.push(i18n.t("errors.emailRequired"));
    errors.find((i) => i === "email__exists") &&
      result.push(i18n.t("errors.emailExists"));
    errors.find((i) => i === "email__update_too_often") &&
      result.push(i18n.t("errors.changeEmailTooOften"));
    return result;
  },
  changeCurrentPasswordErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "current_password__required") &&
      result.push(i18n.t("errors.passwordRequired"));
    errors.find((i) => i === "current_password__invalid") &&
      result.push(i18n.t("errors.passwordInvalid"));
    return result;
  },
  changeNewPasswordErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "new_password__required") &&
      result.push(i18n.t("errors.passwordRequired"));
    errors.find((i) => i === "new_password__weak") &&
      result.push(i18n.t("errors.passwordInvalid"));
    return result;
  },
  confirmEmailStatus({ errors }) {
    if (errors[0] === "token__already_used") {
      return {
        isConfirmed: true,
        title: i18n.t("emailConfirmation.emailAlready"),
        description: i18n.t("emailConfirmation.loginToProceed"),
      };
    } else if (errors[0] === "token__expired") {
      return {
        isConfirmed: false,
        title: i18n.t("emailConfirmation.linkExpired"),
      };
    } else if (errors[0] === "token__invalid") {
      return {
        isConfirmed: false,
        title: i18n.t("emailConfirmation.linkInvalid"),
      };
    }
    return {
      isConfirmed: true,
      title: i18n.t("emailConfirmation.emailConfirmed"),
    };
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
