<template>
  <div class="d-flex flex-column align-center">
    <v-img max-width="571" height="318" :src="finishedIcon" class="mb-10" />
    <h2 class="f-24 arabic-black purple-2--text text-center">
      {{ $t("headers.financingSubmitted") }}
    </h2>
  </div>
</template>
<script>
import finishedIcon from "@/assets/icons/registration-finished.svg";

export default {
  data() {
    return {
      finishedIcon,
    };
  },
};
</script>
