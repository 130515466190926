import Vue from "vue";
import VueRouter from "vue-router";
import SecondStep from "@/components/registration/SecondStep.vue";
import FirstStep from "@/components/registration/FirstStep.vue";
import FourthStep from "@/components/registration/FourthStep.vue";
import ThirdStep from "@/components/registration/ThirdStep.vue";
import Finish from "@/components/registration/Finish.vue";
import Shopify from "@/components/company/store-authorized/Shopify.vue";
import Salla from "@/components/company/store-authorized/Salla.vue";
import Zid from "@/components/company/store-authorized/Zid.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    path: "/sign-up",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/Registration.vue"),
    children: [
      {
        path: "",
        name: "Sign up",
        component: FirstStep,
      },
      {
        path: "company",
        component: SecondStep,
      },
      {
        path: "request",
        component: FourthStep,
      },
      {
        path: "shop",
        component: ThirdStep,
      },
      {
        path: "finished",
        name: "Finished",
        component: Finish,
      },
    ],
  },
  {
    path: "/sign-in",
    name: "Sign in",
    meta: {
      layout: "Empty",
      auth: false,
    },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/reset-password",
    name: "Password update",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/views/NewPassword.vue"),
  },
  {
    path: "/email-confirmation",
    name: "Email confirmation",
    meta: {
      layout: "Empty",
    },
    component: () => import("@/components/profile/EmailConfirmation.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Profile.vue"),
  },
  {
    path: "/company",
    name: "Company",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Company.vue"),
    children: [
      {
        path: "shopify-authorized",
        meta: {
          layout: "Main",
        },
        component: Shopify,
      },
      {
        path: "salla-authorized",
        meta: {
          layout: "Main",
        },
        component: Salla,
      },
      {
        path: "zid-authorized",
        meta: {
          layout: "Main",
        },
        component: Zid,
      },
    ],
  },
  {
    path: "/company/add",
    name: "Company add",
    meta: {
      layout: "Main",
    },
    component: () => import("@/components/company/CompanyAdd.vue"),
  },
  {
    path: "/requests",
    name: "Requests",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Requests.vue"),
  },
  {
    path: "/new-request",
    name: "New Request",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/NewRequest.vue"),
  },
  {
    path: "/requests/:id",
    name: "Request",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Request.vue"),
  },
  {
    path: "/financing",
    name: "Financing",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/Loans.vue"),
  },
  {
    path: "/financing/:id",
    name: "Financing detail",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/LoanDetail.vue"),
  },
  {
    path: "/offerings",
    name: "Offerings",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/OfferingList.vue"),
  },
  {
    path: "/offerings-order",
    name: "Offering checkout",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/OfferingOrder.vue"),
  },
  {
    path: "/new-bnpl",
    name: "New BNPL",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/NewBNPL.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/OrdersList.vue"),
  },
  {
    path: "/orders/:id",
    name: "Order",
    meta: {
      layout: "Main",
    },
    component: () => import("@/views/OrderDetail.vue"),
  },
  {
    path: "*",
    redirect: { name: "Dashboard" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
