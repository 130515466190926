import financingApi from "@/api/financing";

const state = {
  isLoading: false,
  conditions: null,
  errors: [],
  isSubmitting: false,
};
const mutations = {
  getConditionsStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getConditionsSuccess(state, payload) {
    state.conditions = payload;
    state.isLoading = false;
    state.errors = [];
  },
  getConditionsFailed(state, payload) {
    state.errors = payload;
    state.isLoading = false;
  },
  applyFinancingStart(state) {
    state.isSubmitting = true;
    state.errors = [];
  },
  applyFinancingSuccess(state) {
    state.isSubmitting = false;
    state.errors = [];
  },
  applyFinancingFailed(state, payload) {
    state.errors = payload;
    state.isSubmitting = false;
  },
};
const actions = {
  async getFinancingConditions(context) {
    try {
      context.commit("getConditionsStart");

      const response = await financingApi.getConditions();
      context.commit("getConditionsSuccess", response.data);

      return true;
    } catch (e) {
      context.commit("getConditionsFailed", e.response.data.error);
      return false;
    }
  },

  async applyFinancing(context, payload) {
    try {
      context.commit("applyFinancingStart");

      const res = await financingApi.applyFinancing(payload);
      context.commit("applyFinancingSuccess");
      return res.data.number;
    } catch (e) {
      context.commit("applyFinancingFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
