<template>
  <div class="d-flex align-center justify-center circular-loader">
    <v-progress-circular
      :size="90"
      :width="10"
      indeterminate
      color="purple-1"
    ></v-progress-circular>
  </div>
</template>
<script>
export default {
  name: "Loader",
};
</script>
