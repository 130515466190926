<template>
  <v-app :key="key" :dir="rtl">
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>
<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
import { mapState } from "vuex";

export default {
  components: { EmptyLayout, MainLayout },

  data() {
    return {
      key: 1,
    };
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
    ...mapState({
      lang: (state) => state.common.langValue,
      currentCompanyCounter: (state) => state.company.counter,
    }),
    rtl() {
      return this.$vuetify.rtl === true ? "rtl" : "ltr";
    },
  },
  watch: {
    lang() {
      ++this.key;
    },
    currentCompanyCounter() {
      ++this.key;
    },
  },
};
</script>

<style lang="scss">
#app {
  * {
    font-family: "Frutiger", sans-serif !important;
  }
  .arabic-black {
    font-family: "Frutiger Black", sans-serif !important;
  }
  .arabic-bold {
    font-family: "Frutiger Bold", sans-serif !important;
  }
  .arabic-light {
    font-family: "Frutiger Light", sans-serif !important;
  }
  .arabic-default {
    font-family: "Frutiger", sans-serif !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state),
  > .v-input__control >,
  .v-input__slot fieldset {
    color: #b0c3cc;
  }
}

.link {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.two-rows {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
  max-height: 42px;
}

.thee-rows {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  max-height: 70px;
}

.view-without-scroll {
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}
.view-without-scroll-y {
  overflow: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
}
.view-without-scroll-y::-webkit-scrollbar,
.view-without-scroll::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
.view-without-scroll-y::-webkit-scrollbar {
  height: 0px;
}

.v-picker__body {
  max-width: 400px !important;
  width: 300px !important;
}

.v-date-picker-table--month {
  overflow-y: auto;
  overflow-x: hidden;
}

.v-date-picker-header > button > .v-btn__content {
  color: black !important;
}

.v-date-picker-table--month {
  & td {
    width: 100% !important;
  }
  & tr {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & button {
    min-width: fit-content !important;
    padding: 0 10px !important;
  }

  & .v-btn__content {
    //color: black !important;
  }
}

.info__section {
  border: 1px solid #d8e5eb;
  border-radius: 12px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.06);
  background: white;
  padding: 40px;
  overflow: hidden;
}

.v-application {
  & p {
    margin-bottom: 0 !important;
  }
}

.phone-container {
  & .v-input__prepend-inner {
    margin-top: 12px !important;
    padding: 0 !important;
  }
}

.search-input {
  & .v-input__prepend-inner {
    margin-top: 12px !important;
  }
}

input {
  padding: 0;
}
#app .v-btn {
  letter-spacing: 0 !important;
  &.v-btn--disabled.v-btn--has-bg {
    background-color: var(--v-gray-2-base) !important;
  }
}

.btn-shadow {
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-17 {
  font-size: 17px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-21 {
  font-size: 21px !important;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-25 {
  font-size: 25px;
}

.f-30 {
  font-size: 30px !important;
}

.f-32 {
  font-size: 32px !important;
}

.btn-text {
  text-transform: lowercase;
  font-size: 1rem;
  font-weight: 500;
  &::first-letter {
    text-transform: capitalize;
  }
}

.fl-uppercase {
  text-transform: lowercase;
  &::first-letter {
    text-transform: capitalize;
  }
}

.btn-underline--purple {
  border-bottom: 1px solid #6c3cea;
  padding: 0 4px !important;
}

.radius-34 {
  border-radius: 34px !important;
}
.radius-22 {
  border-radius: 16px !important;
}

.radius-16 {
  border-radius: 16px !important;
}

.radius-12 {
  border-radius: 12px !important;
}
.radius-10 {
  border-radius: 10px !important;
}
.radius-8 {
  border-radius: 8px !important;
}
.radius-6 {
  border-radius: 6px !important;
}
.radius-4 {
  border-radius: 4px !important;
}
.radius-0 {
  border-radius: 0 !important;
}

.centered-input input {
  text-align: center;
}

.v-input .v-input__slot {
  border-radius: 8px !important;
  min-height: 40px !important;
  background-color: #fdfdfd !important;
}

.v-input__append-inner {
  align-self: center !important;
  margin: 0 !important;
}

.underline-link {
  display: inline-block;
  position: relative;

  &::after {
    content: "";
    height: 1px;
    width: 105%;
    background-color: #6c3cea;
    position: absolute;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
  }
  &.black--text::after {
    background-color: black;
  }
  &.gray-1--text::after {
    background-color: var(--v-gray-1-base);
  }
}
.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.v-window {
  overflow: unset !important;
}
.platform-wrapper {
  &.v-list {
    padding: 0 !important;
  }
  & .v-list-item {
    border-radius: 16px !important;
    border: 1px solid #b0c3cc;
    &--active {
      border: 1px solid #6c3cea !important;
    }
    &::before {
      border-radius: 16px !important;
    }
  }
}

.selected-mark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  display: none;
}

.blue-border {
  border: 1px solid #d8e5eb;
}

.platform--selected {
  & .selected-mark {
    display: block;
  }
}

label {
  display: block;
}

.v-dialog {
  border-radius: 16px !important;
}

.v-text-field__suffix {
  color: #737b8b;
}

.close-btn {
  position: absolute !important;
  top: 24px;
  right: 24px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute !important;
}

.loader--white .v-progress-circular {
  color: white;
}

.hidden {
  overflow: hidden;
}

.alert-red {
  background-color: #ffeeee !important;
}

table {
  thead {
    tr {
      th {
        min-height: 50px !important;
        background-color: #f5f8fa !important;

        span {
          display: flex;
          align-items: center;
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 16px;
          color: var(--v-gray-1-base);
        }
      }
    }
  }
  tbody {
    td {
      height: 50px !important;
      font-size: 16px !important;
      vertical-align: middle;
      color: black;
    }
  }
}
.table-footer {
  .v-input {
    max-width: 85px;
  }
  .v-input__slot {
    min-height: 32px !important;
  }
}
#app {
  .v-pagination__navigation,
  .v-pagination__item,
  .v-pagination__item--active {
    box-shadow: none;
    margin: 5px 2px;
  }
  .v-pagination__item,
  .v-pagination__navigation {
    width: 34px;
    height: 34px;
    border: 1px solid var(--v-blue-1-base);
  }
}

.gray-border {
  border: 1px solid #d8e5eb !important;
}

.border-bottom--gray {
  border-bottom: 1px solid #d8e5eb;
}

.circular-loader {
  position: absolute;
  left: calc(50%);
  top: 50%;
  transform: translate(-50%, -50%);
}
.bg-white {
  background-color: white;
}

.mb-2px {
  margin-bottom: 2px;
}

.mb-46px {
  margin-bottom: 46px;
}

.v-input__slot {
  //margin-bottom: 4px !important;
}

.profile__no-company {
  padding-top: 141px;
}

.v-messages__message {
  font-size: 14px;
}

.v-text-field__details {
  margin-bottom: 6px !important;
}

.gray-2--text {
  color: #b0c3cc;
}

.purple-2--text {
  color: #302e75;
}

.mdi-check-underline::before {
  content: url("@/assets/icons/check.svg") !important;
  height: 24px;
}

.mdi-window-close::before {
  content: url("@/assets/icons/notif-close.svg") !important;
}

.mdi-alpha-e::before {
  content: url("@/assets/icons/error-notif-icon.svg") !important;
  height: 24px;
}
</style>
