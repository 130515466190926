import axios from "@/plugins/axios";

const signUp = (data) => {
  return axios.post("/customer/api/v1/sign-up", data);
};

const signOut = () => {
  return axios.post("/customer/api/v1/sign-out");
};

const signUpOtp = (data) => {
  return axios.post("/customer/api/v1/sign-up/otp", data);
};

const resendOtp = (data) => {
  return axios.post("/customer/api/v1/otp-resend", data);
};

const signIn = (data) => {
  return axios.post("/customer/api/v1/sign-in/id", data);
};

const signInOtp = (data) => {
  return axios.post("/customer/api/v1/sign-in/otp", data);
};

const resetPassword = (data) => {
  return axios.post("/customer/api/v1/password-reset-request", data);
};

const updatePassword = (data) => {
  return axios.post("/customer/api/v1/password-reset", data);
};

const confirmEmail = (data) => {
  return axios.post("/customer/api/v1/confirm-email", data);
};

export default {
  signUp,
  signUpOtp,
  resendOtp,
  signIn,
  signInOtp,
  resetPassword,
  updatePassword,
  signOut,
  confirmEmail,
};
