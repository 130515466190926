<template>
  <div>
    <h4 class="f-18 arabic-bold mb-3">{{ $t("company.result") }}</h4>
    <v-list class="company-wrapper" :class="isParticipant ? 'mb-8' : 'mb-3'">
      <v-list-item-group
        v-model="model"
        active-class="company--selected"
        color="purple-1"
      >
        <v-list-item :value="true" class="radius-16" :disabled="!isParticipant">
          <v-list-item-content>
            <v-list-item-title class="f-18 font-weight-bold">{{
              company.cr_name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div class="d-flex align-center justify-center mb-10" v-if="!isParticipant">
      <v-img
        max-width="24"
        height="24"
        alt="Error"
        :src="errorIcon"
        class="me-4"
      />
      <p class="error--text d-inline-block" style="max-width: 480px">
        {{ $t("company.notOwner") }}
      </p>
    </div>
    <v-btn
      width="360"
      height="52"
      color="purple-2"
      class="white--text mx-auto d-block btn-shadow mb-2 radius-8"
      :disabled="!isParticipant || !model"
      :loading="isAttaching"
      @click="onAttachCompany"
    >
      <span class="btn-text font-weight-bold">{{ $t("btn.continue") }}</span>
    </v-btn>
  </div>
</template>
<script>
import { mapState } from "vuex";
import errorIcon from "@/assets/icons/error.svg";

export default {
  data() {
    return {
      model: false,
      errorIcon,
    };
  },

  methods: {
    getRequestData() {
      return {
        cr_number: this.company.cr_number,
      };
    },
    async onAttachCompany() {
      const res = await this.$store.dispatch(
        "attachCompany",
        this.getRequestData()
      );

      if (res) {
        this.$router.push("/sign-up/shop?type=platform");
      }
    },
  },

  computed: {
    ...mapState({
      company: (state) => state.company.company,
      isAttaching: (state) => state.company.isAttaching,
    }),
    isParticipant() {
      return this.company.participant;
    },
  },
};
</script>
<style lang="scss">
.company-wrapper {
  &.v-list {
    padding: 0 !important;
  }
  & .v-list-item {
    border-radius: 16px !important;
    border: 2px solid #b0c3cc;
    &--active {
      border: 2px solid #6c3cea !important;
    }
    &::before {
      border-radius: 16px !important;
    }
  }
}
</style>
