import api from "@/api/offerings";
import router from "@/router";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  isLoadingPreview: false,
  list: {},
  item: {},
  offeringPartners: [],
  offeringCategories: [],
  offeringCard: {},
  offeringPreview: {},
  offeringOrderPreview: {},
  errors: [],
};

const mutations = {
  resetOfferingsErrors(state) {
    state.errors = [];
  },
  getOfferingListStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getOfferingListSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.list = payload;
  },
  getOfferingListFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getOfferingStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getOfferingSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.item = payload;
  },
  getOfferingFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  GET_PARTNERS: (state, payload) => {
    state.offeringPartners = payload;
  },

  GET_CATEGORIES: (state, payload) => {
    state.offeringCategories = payload;
  },

  getOfferingCardStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getOfferingCardSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.offeringCard = payload;
  },
  getOfferingCardFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getOfferingPreviewStart(state) {
    state.isLoadingPreview = true;
    state.errors = [];
  },
  getOfferingPreviewSuccess(state, payload) {
    state.isLoadingPreview = false;
    state.errors = [];
    state.offeringPreview = payload;
  },
  getOfferingPreviewFailed(state, payload) {
    state.isLoadingPreview = false;
    state.errors = payload;
  },

  getOfferingOrderPreviewStart(state) {
    state.isLoadingPreview = true;
    state.errors = [];
  },
  getOfferingOrderPreviewSuccess(state, payload) {
    state.isLoadingPreview = false;
    state.errors = [];
    state.offeringOrderPreview = payload;
  },
  getOfferingOrderPreviewFailed(state, payload) {
    state.isLoadingPreview = false;
    state.errors = payload;
  },

  getOfferingOrderRequestStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getOfferingOrderRequestSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.offeringCard = payload;
  },
  getOfferingOrderRequestFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
  resetOfferingOrderPreview(state) {
    state.offeringOrderPreview = {};
  },
};

const actions = {
  async getOfferingList(context, payload) {
    try {
      context.commit("getOfferingListStart");

      const res = await api.getOfferingList(payload);
      context.commit("getOfferingListSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingListFailed", e.response.data.error);
      return false;
    }
  },

  async getOfferingItem(context, payload) {
    try {
      context.commit("getOfferingStart");

      const res = await api.getOfferingItem(payload);
      context.commit("getOfferingSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingFailed", e.response.data.error);
      return false;
    }
  },

  async getOfferingPartners(context) {
    try {
      const res = await api.getOfferingPartners();
      context.commit("GET_PARTNERS", res.data);
      return true;
    } catch (e) {
      return false;
    }
  },

  async getOfferingCategories(context) {
    try {
      const res = await api.getOfferingCategories();
      context.commit("GET_CATEGORIES", res.data);
      return true;
    } catch (e) {
      return false;
    }
  },

  async getOfferingCard(context) {
    try {
      context.commit("getOfferingCardStart");

      const res = await api.getOfferingCard();
      context.commit("getOfferingCardSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingCardFailed", e.response.data.error);
      return false;
    }
  },

  async addOfferingCard(context, payload) {
    try {
      const res = await api.addOfferingCard(payload);
      context.commit("getOfferingCardSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingCardFailed", e.response.data.error);
      return false;
    }
  },

  async removeOfferingCard(context, payload) {
    try {
      const res = await api.removeOfferingCard(payload);
      context.commit("getOfferingCardSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingCardFailed", e.response.data.error);
      return false;
    }
  },

  async previewOfferingCard(context) {
    try {
      context.commit("getOfferingPreviewStart");

      const res = await api.previewOfferingCard();
      context.commit("getOfferingPreviewSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingPreviewFailed", e.response.data.error);
      return false;
    }
  },

  async previewOfferingOrder(context, payload) {
    try {
      context.commit("getOfferingOrderPreviewStart");

      const res = await api.previewOfferingOrder(payload);
      context.commit("getOfferingOrderPreviewSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getOfferingOrderPreviewFailed", e.response.data.error);
      return false;
    }
  },

  async submitOfferingCard(context, payload) {
    try {
      const res = await api.submitOfferingCard(payload);
      context.commit("getOfferingCardSuccess", res.data);
      router.push(`/orders/${res.data[0].number}`);
      return res;
    } catch (e) {
      context.commit("getOfferingCardFailed", e.response.data.error);
      return false;
    }
  },

  async submitOfferingOrder(context, payload) {
    context.commit("getOfferingOrderRequestStart");
    try {
      const res = await api.submitOfferingOrder(payload);
      context.commit("getOfferingOrderRequestSuccess", res.data);
      router.push(`/orders/${res.data.number}`);
      return res;
    } catch (e) {
      context.commit("getOfferingOrderRequestFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  offeringList(state) {
    return state.list?.result || [];
  },
  offeringCardList(state) {
    return state.offeringCard?.items || [];
  },
  offeringPreviewList(state) {
    return state.offeringPreview?.items || [];
  },
  partnerTitleErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "partner_title__required") &&
      result.push(i18n.t("errors.partnerTitleRequired"));
    errors.find((i) => i === "partner_title__invalid") &&
      result.push(i18n.t("errors.partnerTitleInvalid"));
    return result;
  },
  partnerCrNumberErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "partner_cr_number__required") &&
      result.push(i18n.t("errors.partnerCrNumberRequired"));
    errors.find((i) => i === "partner_cr_number__invalid") &&
      result.push(i18n.t("errors.partnerCrNumberInvalid"));
    return result;
  },
  partnerPhoneNumberErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "phone_code__required") &&
      result.push(i18n.t("errors.partnerPhoneCodeRequired"));
    errors.find((i) => i === "phone_code__invalid") &&
      result.push(i18n.t("errors.partnerPhoneCodeInvalid"));
    errors.find((i) => i === "phone_number__required") &&
      result.push(i18n.t("errors.partnerPhoneNumberRequired"));
    errors.find((i) => i === "phone_number__invalid") &&
      result.push(i18n.t("errors.partnerPhoneNumberInvalid"));
    return result;
  },
  partnerEmailErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "partner_email__invalid") &&
      result.push(i18n.t("errors.partnerEmailInvalid"));
    return result;
  },
  partnerItemDescriptionErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "item_description__required") &&
      result.push(i18n.t("errors.itemDescriptionRequired"));
    errors.find((i) => i === "item_description__invalid") &&
      result.push(i18n.t("errors.itemDescriptionInvalid"));
    return result;
  },
  partnerItemTotalErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "item_total__required") &&
      result.push(i18n.t("errors.itemTotalRequired"));
    errors.find((i) => i === "item_total__invalid") &&
      result.push(i18n.t("errors.itemTotalInvalid"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
