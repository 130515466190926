import companyApi from "@/api/company";
import { i18n } from "@/plugins/i18n";

const state = {
  isLoading: false,
  company: null,
  errors: [],
  shopifyErrors: [],
  isAttaching: false,
  isConnecting: false,
  storeUrl: null,
  companies: [],
  isCompanyLoading: false,
  counters: null,
  companyPerformance: {},
  counter: 0,
  isCookieCompany: false,
};

const mutations = {
  setCookieCompany() {
    state.isCookieCompany = true;
  },
  resetCompanyErrors(state) {
    state.errors = [];
  },
  resetCompany(state) {
    state.company = null;
  },
  findCompanyStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  findCompanySuccess(state, payload) {
    state.isLoading = false;
    state.company = payload;
    state.errors = [];
  },
  findCompanyFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
    state.company = null;
  },
  attachCompanyStart(state) {
    state.isAttaching = true;
    state.errors = [];
  },
  attachCompanySuccess(state) {
    state.isAttaching = false;
    state.errors = [];
  },
  attachCompanyFailed(state, payload) {
    state.isAttaching = false;
    state.errors = payload;
  },

  connectStoreStart(state) {
    state.isConnecting = true;
    state.errors = [];
  },
  connectStoreSuccess(state, payload) {
    state.isConnecting = false;
    state.errors = [];
    state.storeUrl = payload;
  },
  connectStoreFailed(state, payload) {
    state.isConnecting = false;
    state.errors = payload;
  },

  connectShopifyStart(state) {
    state.isConnecting = true;
    state.shopifyErrors = [];
  },
  connectShopifySuccess(state, payload) {
    state.isConnecting = false;
    state.shopifyErrors = [];
    state.storeUrl = payload;
  },
  connectShopifyFailed(state, payload) {
    state.isConnecting = false;
    state.shopifyErrors = payload;
  },

  shopifyAuthStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  shopifyAuthSuccess(state) {
    state.isLoading = false;
    state.errors = [];
  },
  shopifyAuthFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getCompaniesStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getCompaniesSuccess(state, payload) {
    state.isLoading = false;
    state.companies = payload;
    state.errors = [];
  },
  getCompaniesFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
  changeCurrentCompanyStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  changeCurrentCompanySuccess(state) {
    state.isLoading = false;
    state.errors = [];
    if (!state.isCookieCompany) {
      state.isCookieCompany = true;
    } else {
      state.counter += 1;
    }
  },
  changeCurrentCompanyFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  getCompanyStart(state) {
    state.isCompanyLoading = true;
    state.errors = [];
  },
  getCompanySuccess(state, payload) {
    state.isCompanyLoading = false;
    state.company = payload;
    state.errors = [];
  },
  getCompanyFailed(state, payload) {
    state.isCompanyLoading = false;
    state.errors = payload;
  },

  getCountersStart(state) {
    state.isCountersLoading = true;
    state.errors = [];
  },
  getCountersSuccess(state, payload) {
    state.isCountersLoading = false;
    state.errors = [];
    state.counters = payload;
  },
  getCountersFailed(state, payload) {
    state.isCountersLoading = false;
    state.errors = payload;
  },

  getCompanyPerformanceStart(state) {
    state.isLoading = true;
    state.errors = [];
  },
  getCompanyPerformanceSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.companyPerformance = payload;
  },
  getCompanyPerformanceFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },
};
const actions = {
  resetCompanyStore(context) {
    context.commit("resetCompany");
  },
  async findCompany(context, payload) {
    try {
      context.commit("findCompanyStart");

      const response = await companyApi.findCompany(payload);
      context.commit("findCompanySuccess", response.data);

      return true;
    } catch (e) {
      context.commit("findCompanyFailed", e.response.data.error);
      return false;
    }
  },
  async attachCompany(context, payload) {
    try {
      context.commit("attachCompanyStart");

      await companyApi.attachCompany(payload);
      context.commit("attachCompanySuccess");

      return true;
    } catch (e) {
      context.commit("attachCompanyFailed", e.response.data.error);
      return false;
    }
  },
  async connectStore(context, payload) {
    try {
      context.commit("connectShopifyStart");

      const res = await companyApi.connectStore(payload);
      context.commit("connectShopifySuccess", res.data.authorization_url);

      return true;
    } catch (e) {
      context.commit("connectShopifyFailed", e.response.data.error);
      return false;
    }
  },
  async connectSallaStore(context, payload) {
    try {
      context.commit("connectStoreStart");

      const res = await companyApi.connectSallaStore(payload);
      context.commit("connectStoreSuccess", res.data.authorization_url);

      return true;
    } catch (e) {
      context.commit("connectStoreFailed", e.response.data.error);
      return false;
    }
  },
  async connectZidStore(context, payload) {
    try {
      context.commit("connectStoreStart");

      const res = await companyApi.connectZidStore(payload);
      context.commit("connectStoreSuccess", res.data.authorization_url);

      return true;
    } catch (e) {
      context.commit("connectStoreFailed", e.response.data.error);
      return false;
    }
  },
  async shopifyAuth(context, payload) {
    try {
      context.commit("shopifyAuthStart");

      const res = await companyApi.shopifyAuth(payload);
      context.commit("shopifyAuthSuccess");

      return res;
    } catch (e) {
      context.commit("shopifyAuthFailed", e.response.data.error);
      return false;
    }
  },
  async sallaAuth(context, payload) {
    try {
      context.commit("shopifyAuthStart");

      const res = await companyApi.sallaAuth(payload);
      context.commit("shopifyAuthSuccess");

      return res;
    } catch (e) {
      context.commit("shopifyAuthFailed", e.response.data.error);
      return false;
    }
  },
  async zidAuth(context, payload) {
    try {
      context.commit("shopifyAuthStart");

      const res = await companyApi.zidAuth(payload);
      context.commit("shopifyAuthSuccess");

      return res;
    } catch (e) {
      context.commit("shopifyAuthFailed", e.response.data.error);
      return false;
    }
  },
  async getCompanies(context, payload = "") {
    try {
      context.commit("getCompaniesStart");

      const response = await companyApi.getCompanies(payload);
      context.commit("getCompaniesSuccess", response.data);

      return true;
    } catch (e) {
      context.commit("getCompaniesFailed", e.response.data.error);
      return false;
    }
  },
  async getCompany(context) {
    try {
      context.commit("getCompanyStart");

      const response = await companyApi.getCompany();
      context.commit("getCompanySuccess", response.data);

      return true;
    } catch (e) {
      context.commit("getCompanyFailed", e.response.data.error);
      return false;
    }
  },
  async changeCurrentCompany(context, payload) {
    try {
      context.commit("changeCurrentCompanyStart");

      const response = await companyApi.changeCurrentCompany(payload);
      context.commit("changeCurrentCompanySuccess", response.data);

      return true;
    } catch (e) {
      context.commit("changeCurrentCompanyFailed", e.response.data.error);
      return false;
    }
  },
  async getCounters(context, payload) {
    try {
      context.commit("getCountersStart");

      const res = await companyApi.getCounters(payload);
      context.commit("getCountersSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCountersFailed", e.response.data.error);
      return false;
    }
  },

  async getCompanyPerformance(context) {
    try {
      context.commit("getCompanyPerformanceStart");

      const res = await companyApi.getCompanyPerformance();
      context.commit("getCompanyPerformanceSuccess", res.data);
      return true;
    } catch (e) {
      context.commit("getCompanyPerformanceFailed", e.response.data.error);
      return false;
    }
  },
};

const getters = {
  companyErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "cr_number__required") &&
      result.push(i18n.t("errors.crNumberRequired"));
    errors.find((i) => i === "cr_number__invalid") &&
      result.push(i18n.t("errors.crNumberInvalid"));
    errors.find((i) => i === "company__not_found") &&
      result.push(i18n.t("errors.companyNotFound"));
    errors.find((i) => i === "action__limit_exceeded") &&
      result.push(i18n.t("errors.limitExceeded"));
    errors.find((i) => i === "customer__not_in_parties") &&
      result.push(i18n.t("errors.notInParties"));
    errors.find((i) => i === "company__already_added") &&
      result.push(i18n.t("errors.customerAlreadyAdded"));
    errors.find((i) => i === "action__not_allowed") &&
      result.push(i18n.t("errors.companyAnotherOwner"));
    return result;
  },
  storeErrors({ shopifyErrors }) {
    const result = [];
    shopifyErrors.find((i) => i === "store_name__required") &&
      result.push(i18n.t("errors.storeNameRequired"));
    shopifyErrors.find((i) => i === "store_name__invalid") &&
      result.push(i18n.t("errors.storeNameInvalid"));
    shopifyErrors.find((i) => i === "shop__already_connected") &&
      result.push(i18n.t("errors.shopAlreadyConnected"));
    shopifyErrors.find((i) => i === "store_name__not_found") &&
      result.push(i18n.t("errors.storeNameNotFound"));
    shopifyErrors.find((i) => i === "shop_with_same_name_and_type__exists") &&
      result.push(i18n.t("errors.sallaStoreSameName"));
    return result;
  },
  zidSallaStoreErrors({ errors }) {
    const result = [];
    errors.find((i) => i === "shop__already_connected") &&
      result.push(i18n.t("errors.sallaShopAlreadyConnected"));
    errors.find((i) => i === "company__not_found") &&
      result.push(i18n.t("errors.sallaStoreNameNotFound"));
    errors.find((i) => i === "shop_with_same_name_and_type__exists") &&
      result.push(i18n.t("errors.sallaStoreSameName"));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
