import notificationApi from "@/api/notification";
import router from "@/router";
import { getItem } from "@/helpers/persistanceStorage";

let socket = null;

const state = {
  notificationsList: {},
  notificationItem: {},
  newNotification: null,
  notificationsCount: null,
  showNotifications: false,
  socketData: {},
  isLoading: false,
  errors: [],
  isReadingAll: false,
};

const mutations = {
  changeNotificationsId(state) {
    state.notificationsId = state.notificationsId + 1;
  },
  setNtfCount(state, payload) {
    state.notificationsCount = payload;
  },
  getNotificationsStart(state) {
    state.isLoading = true;
    state.errors = [];
    state.notificationsList = {};
  },
  getNotificationsSuccess(state, payload) {
    state.isLoading = false;
    state.errors = [];
    state.notificationsList = payload;
  },
  getNotificationsFailed(state, payload) {
    state.isLoading = false;
    state.errors = payload;
  },

  readAllStart(state) {
    state.isReadingAll = true;
    state.errors = [];
  },
  readAllSuccess(state) {
    state.isReadingAll = false;
    state.errors = [];
  },
  readAllFailed(state, payload) {
    state.isReadingAll = false;
    state.errors = payload;
  },
};

const actions = {
  async getNotifications(context, payload) {
    try {
      context.commit("getNotificationsStart");

      const res = await notificationApi.getNotifications(payload);
      context.commit("getNotificationsSuccess", res.data);
      return res.data;
    } catch (e) {
      context.commit("getNotificationsFailed", e.response.data.error);
      return false;
    }
  },

  async readAll(context) {
    try {
      context.commit("readAllStart");

      await notificationApi.setSeenAll();
      context.commit("readAllSuccess");

      context.state.notificationsList.result.forEach((element) => {
        element.seen = true;
      });

      context.state.notificationsCount = 0;

      return true;
    } catch (e) {
      context.commit("readAllFailed", e.response.data.error);
      return false;
    }
  },

  async getNotificationsCount(context) {
    try {
      const res = await notificationApi.getNewMsgCount();
      context.commit("setNtfCount", res.data.new_notifications_count);
      return true;
    } catch (e) {
      return false;
    }
  },

  async readMessage(context, payload) {
    try {
      const res = await notificationApi.setSeenMessage(payload);

      const idx = context.state.notificationsList.result.findIndex(
        (i) => i.id === payload
      );
      context.state.notificationsList.result[idx].seen = true;
      context.state.notificationItem = res.data;
      context.state.notificationsCount > 0
        ? (context.state.notificationsCount -= 1)
        : null;
      return true;
    } catch (e) {
      return false;
    }
  },

  openWebSocket(context) {
    const token = getItem("accessToken");
    const url =
      process.env.NODE_ENV === "development"
        ? "localhost:8080"
        : window.location.host;
    socket = new WebSocket(
      `wss://${url}/customer/api/v1/ws/updates/?access_token=${token}`
    );

    socket.onopen = () => {};

    socket.onclose = () => {
      if (router.currentRoute.name !== "Sign in") {
        context.dispatch("check");
      }
    };
    socket.onmessage = (e) => {
      const response = JSON.parse(e.data);
      if (response.event === "notification") {
        response.payload.seen = false;
        context.state.notificationsList.result.unshift(response.payload);
        context.state.notificationsCount += 1;
        context.state.newNotification = response.payload;

        setTimeout(() => {
          context.state.newNotification = null;
        }, 3000);
      }
    };
    socket.onerror = () => {
      setTimeout(() => {
        if (router.currentRoute.name !== "Sign in") {
          context.dispatch("check");
        }
      }, 100);
    };
  },

  check(context) {
    if (!socket || socket.readyState === 3) {
      context.dispatch("openWebSocket");
    }
  },
};

const getters = {
  notifications(state) {
    return state.notificationsList.result || [];
  },
  notificationPages(state) {
    return state.notificationsList?.pages || 0;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
