<template>
  <v-card flat width="540" class="mx-auto">
    <h1 class="text-center arabic-black f-24 purple-2--text mb-5">
      {{ $t("headers.enterCompany") }}
    </h1>
    <v-form
      class="d-flex align-start search-wrapper"
      @submit.prevent="onFindCompany"
    >
      <v-text-field
        color="purple-1"
        outlined
        height="60"
        v-model="crNumber"
        :placeholder="`${$t('label.enterCR')}`"
        class="cr-field"
        :error-messages="companyErrors"
      ></v-text-field>
      <v-btn
        color="purple-2"
        height="60"
        width="100"
        class="btn-shadow radius-8 white--text"
        :loading="isLoading"
        type="submit"
      >
        <span class="btn-text">{{ $t("btn.search") }}</span>
      </v-btn>
    </v-form>
    <v-expand-transition>
      <CompanySelection v-if="company" />
    </v-expand-transition>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import queryString from "query-string";
import CompanySelection from "@/components/registration/CompanySelection.vue";

export default {
  components: { CompanySelection },
  data() {
    return {
      crNumber: "",
    };
  },

  mounted() {
    this.$store.commit("setStep", 2);
  },

  methods: {
    getCompanyParams() {
      return queryString.stringify({
        cr_number: this.crNumber,
      });
    },
    onFindCompany() {
      this.$store.dispatch("findCompany", this.getCompanyParams());
    },
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.company.isLoading,
      company: (state) => state.company.company,
    }),
    ...mapGetters({
      companyErrors: "companyErrors",
    }),
  },
};
</script>
<style lang="scss">
.cr-field .v-input__slot {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.search-wrapper .v-btn {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
</style>
