import api from "@/api/common";

const state = {
  langValue: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
  config: {},
};

const mutations = {
  setLang(state, payload) {
    state.langValue = payload;
  },
  GET_CONFIG: (state, payload) => {
    state.config = payload;
  },
};
const actions = {
  async getConfig(context) {
    try {
      const res = await api.getConfig();
      context.commit("GET_CONFIG", res.data);
      return true;
    } catch (e) {
      return false;
    }
  },
};

const getters = {
  loansEnabled(state) {
    return state.config.loans?.enabled || false;
  },
  offeringEnabled(state) {
    return state.config.offering?.enabled || false;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
