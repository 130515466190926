<template>
  <div
    class="main d-flex flex-column"
    :class="$vuetify.rtl ? 'right-sidebar' : 'left-sidebar'"
  >
    <notifications></notifications>
    <Menu />
    <Header />
    <router-view />
  </div>
</template>
<script>
import Header from "@/components/shared/Header.vue";
import Menu from "@/components/shared/Menu.vue";

export default {
  components: { Header, Menu },
};
</script>
<style lang="scss" scoped>
.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
