<template>
  <div class="d-flex align-center justify-space-between px-8 py-5 header">
    <v-img
      alt="Logo"
      :src="require('@/assets/icons/logo.svg')"
      max-width="141"
      height="34"
    />

    <div class="d-flex align-center">
      <v-btn
        icon
        elevation="0"
        color="gray"
        class="me-5"
        @click="changeLanguage"
      >
        <v-img
          height="24"
          max-width="24"
          :src="require('@/assets/icons/language.svg')"
        ></v-img>
      </v-btn>

      <div v-if="!isEmailConfirm" class="gray-1--text">
        <div v-if="isSignin" class="d-flex align-center">
          <p class="me-3">{{ $t("auth.noAccount") }}</p>
          <router-link
            to="/sign-up"
            class="font-weight-bold purple-1--text underline-link"
          >
            {{ $t("auth.registration") }}
          </router-link>
        </div>
        <div v-else class="d-flex align-center">
          <p class="me-3">{{ $t("auth.haveAccount") }}</p>
          <router-link
            to="/sign-in"
            class="font-weight-bold purple-1--text underline-link"
          >
            <span class="purple-1--text">{{ $t("auth.login") }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/plugins/axios";

export default {
  data() {
    return {};
  },

  mounted() {
    this.checkLocalization();
  },

  methods: {
    checkLocalization() {
      axios.defaults.headers.common["Accept-Language"] =
        localStorage.getItem("lang") || "en";
      const locale = localStorage.getItem("lang");
      this.$i18n.locale = locale || "en";
      this.$vuetify.rtl = locale === "ar";
    },
    changeLanguage() {
      const newLang = this.$i18n.locale === "ar" ? "en" : "ar";
      this.$i18n.locale = newLang;
      this.$vuetify.rtl = newLang === "ar";
      axios.defaults.headers.common["Accept-Language"] = newLang;
      localStorage.setItem("lang", newLang);
      this.$store.commit("setLang", newLang);
    },
  },

  computed: {
    isSignin() {
      return this.$route.name === "Sign in";
    },

    isEmailConfirm() {
      return this.$route.name === "Email confirmation";
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #e3e3e3;
}
</style>
